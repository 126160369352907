/** @format */

import { LOCALES } from '../../utils/gatsby/constants';
import { TLocale, TLocalesObject } from '../../utils/gatsby/types';

export function reduceLocales<T>(value: (locale: TLocale) => T): TLocalesObject<T> {
  return LOCALES.reduce<TLocalesObject<T>>((acc, curr) => {
    acc[curr] = value(curr);
    return acc;
  }, {} as TLocalesObject<T>);
}
