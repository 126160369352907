/** @format */

import { TListingAction } from '@/redux/declarations';
import { DEFAULT_FILTERS, INITIAL_STATE } from '@/ducks/listing/constants';

export const reducerListing = (prevState = INITIAL_STATE, action: TListingAction) => {
  switch (action.type) {
    case 'LISTING_RESULT_REQUEST':
      return {
        ...prevState,
        ...action.payload,
      };
    case 'LISTING_SET_FILTERS':
      return {
        ...prevState,
        filters: {
          ...prevState.filters,
          ...action.payload,
        },
      };
    case 'LISTING_RESET_FILTERS': {
      let results = prevState.results;
      if (prevState.results.items?.length === 0) {
        results = INITIAL_STATE.results;
      }

      return {
        ...prevState,
        results,
        filters: { ...DEFAULT_FILTERS },
        pageIndex: 1,
      };
    }
    case 'LISTING_SET_RESULT_SUCCESS':
      return {
        ...prevState,
        results: action.payload,
        resultCount: action.payload.length,
        loading: false,
      };
    case 'LISTING_SET_RESULT_FAILURE':
      return {
        ...prevState,
        loading: false,
      };

    case 'LISTING_SET_PROPERTY_TYPE_FILTERS':
      return {
        ...prevState,
        filters: {
          ...prevState.filters,
          propertyType: {
            ...prevState.filters.propertyType,
            ...action.payload,
          },
        },
      };
    case 'LISTING_SET_CHECK_IN_DATE_FILTER':
      return {
        ...prevState,
        filters: {
          ...prevState.filters,
          checkInDate: action.payload,
        },
      };
    case 'LISTING_SET_POI_FILTERS':
      return {
        ...prevState,
        filters: {
          ...prevState.filters,
          positions: action.payload,
        },
      };
    case 'LISTING_SET_MONTHLY_PRICE_FILTERS':
      return {
        ...prevState,
        filters: {
          ...prevState.filters,
          monthlyPrice: action.payload,
        },
      };
    case 'LISTING_SET_ORDER_BY_FILTER':
      return {
        ...prevState,
        filters: {
          ...prevState.filters,
          orderBy: action.payload,
        },
      };
    case 'LISTING_SET_MAP_AREA_FILTER':
      return {
        ...prevState,
        filters: {
          ...prevState.filters,
          areas: action.payload, // coordinates
        },
      };
    case 'LISTING_SET_LOADING': {
      return {
        ...prevState,
        loading: action.payload,
      };
    }
    case 'LISTING_SET_SEARCH_BY_MAP': {
      return {
        ...prevState,
        searchByMap: action.payload,
      };
    }
    case 'LISTING_SET_QUERY': {
      return {
        ...prevState,
        query: action.payload,
      };
    }
    default:
      return prevState;
  }
};
