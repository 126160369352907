/** @format */

/* eslint-disable @typescript-eslint/default-param-last */

import { assign } from 'lodash';

import { TAction } from '../../redux/declarations';
import { KEY_STEP_CHECK_DATES, KEY_STEP_ERRORS, KEY_STEP_IS_BOOKING, KEY_STEP_PAYMENT } from './constants';
import { TReducerBookingOld } from './selectors';

const DEFAULT_STATE = {
  language: '',
  email: '',
  prefix: '',
  phoneNumber: '',
  name: '',
  surname: '',
  birthDate: '',
  birthCity: '',
  gender: '',
  job: '',
  birthNationId: '',
  nationalityId: { label: '' },
  taxCode: '',
  homeAddress: {
    address: '',
    addressNumber: '',
    zipCode: '',
    cityOfResidence: '',
    nationId: '',
  },
  checkinDate: '',
  checkoutDate: '',
};

export const reducerBookingOld = (prevState: TReducerBookingOld = {}, action: TAction): TReducerBookingOld => {
  const gtmStatus = prevState.gtmStatus ? { ...prevState.gtmStatus } : {};
  let newState;

  switch (action.type) {
    case 'SET_BOOKING_STEP':
      return { ...prevState, currentStep: action.payload };
    case 'SET_BOOKING_FORM_AVAILABILITY':
      return { ...prevState, nextButtonEnabled: action.payload };
    case 'SET_BOOKING_FORM_DATA':
      return { ...prevState, form: { ...prevState.form, ...action.payload } };
    case 'SET_BOOKING_CHECK_IN_DATE':
      return {
        ...prevState,
        form: {
          ...prevState.form,
          moveInDate: action.payload,
        },
      };
    case 'SET_BOOKING_CHECK_OUT_DATE':
      return {
        ...prevState,
        form: {
          ...prevState.form,
          moveOutDate: action.payload,
        },
      };
    case 'GET_BOOKING_FORM_OPTIONS_SUCCESS':
      return { ...prevState, options: assign(prevState.options, action.payload) };
    case 'GET_BOOKING_COMMERCIAL_CONDITIONS_SUCCESS':
      return { ...prevState, commercialConditions: action.payload };
    case 'GET_BOOKING_CONTRACTUAL_CONDITIONS_SUCCESS':
      return { ...prevState, contractualConditions: action.payload };
    case 'GET_BOOKING_ROOM_SUCCESS':
      return {
        ...prevState,
        currentStep: KEY_STEP_CHECK_DATES,
        nextButtonEnabled: true,
        room: action.payload,
      };
    case 'CREATE_BOOKING_REQUEST':
      return {
        ...prevState,
        currentStep: KEY_STEP_IS_BOOKING,
        nextButtonEnabled: false,
        formSend: { ...prevState.formSend, state: 'loading' },
      };
    case 'CREATE_BOOKING_SUCCESS':
      return {
        ...prevState,
        currentStep: KEY_STEP_PAYMENT,
        nextButtonEnabled: true,
        paymentIntent: { state: 'done', value: action.payload },
        formSend: { ...prevState.formSend, state: 'done' },
      };
    case 'SET_BOOKING_ERROR':
      return {
        ...prevState,
        currentStep: KEY_STEP_ERRORS,
        formSend: { ...prevState.formSend, state: 'error', messages: action.payload },
      };
    case 'GET_BOOKING_CONTRACTUAL_CONDITIONS_FAILURE':
    case 'GET_BOOKING_COMMERCIAL_CONDITIONS_FAILURE':
    case 'GET_BOOKING_ROOM_AVAILABILITY_FAILURE':
    case 'GET_BOOKING_FORM_OPTIONS_FAILURE':
    case 'CREATE_BOOKING_FAILURE':
      return {
        ...prevState,
        currentStep: KEY_STEP_ERRORS,
        nextButtonEnabled: true,
        formSend: { ...prevState.formSend, state: 'error', messages: action.payload },
      };
    case 'CREATE_BOOKING_PAYMENT_FAILURE':
      newState = {
        ...prevState,
        nextButtonEnabled: true,
        currentStep: KEY_STEP_ERRORS,
        paymentIntent: { ...prevState.paymentIntent, state: 'error' },
        formSend: { ...prevState.formSend, state: 'error', messages: action.payload },
      };
      delete newState.isPaying;

      return newState;
    case 'CREATE_BOOKING_PAYMENT_REQUEST':
      return {
        ...prevState,
        isPaying: true,
      };
    case 'CREATE_BOOKING_PAYMENT_SUCCESS':
      newState = { ...prevState, paymentResult: action.payload };
      delete newState.isPaying;
      return newState;
    case 'SET_BOOKING_GTM_STATUS':
      return {
        ...prevState,
        gtmStatus: {
          ...gtmStatus,
          [action.payload]: true,
        },
      };
    case 'GET_BOOKING_ROOM_AVAILABILITY_SUCCESS':
      return { ...prevState, availability: action.payload };
    case 'RESET_BOOKING_FORM':
      // @ts-ignore
      return { ...prevState, form: DEFAULT_STATE };
    default:
      return prevState;
  }
};
