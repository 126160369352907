/** @format */

export const MIN_LENGTH_TAX_CODE = 8;

export const KEY_STEP_CHECK_DATES = 1;
export const KEY_STEP_PAYMENT_SUMMARY = 2;
export const KEY_STEP_PERSONAL_INFO = 3;
export const KEY_STEP_IS_BOOKING = 4;
export const KEY_STEP_PAYMENT = 6;
export const KEY_STEP_ERRORS = 11;
