/** @format */

import { TSharedAction } from '@/redux/declarations';
import { INITIAL_STATE } from './constants';
import { SharedStore } from './types';

export const reducerShared = (prevState = INITIAL_STATE as SharedStore, action: TSharedAction) => {
  switch (action.type) {
    case 'SHARED_SET_AVAILABILITY_ARRAY':
      return {
        ...prevState,
        availabilityArray: [...prevState.availabilityArray, action.payload],
      };

    case 'SHARED_RESET_AVAILABILITY_ARRAY':
      return {
        ...prevState,
        availabilityArray: [],
      };

    default:
      return prevState;
  }
};
