/** @format */

import { DEFAULT_BOOKING } from '@/ducks/bookingNew/constants';
import { BookingErrorStates, TReducerBooking } from '@/ducks/bookingNew/types';
import { GTMBookingError } from '@/ducks/googleTagManager/helpers/google-tag-manager';
import { TBookingAction, TBookingCreateRemoveErrorsAction } from '@/redux/declarations';
import { utilityGetPrice } from '@/utils/helpers/utilityGetPrice';

export const reducerBooking = (
  prevState: TReducerBooking = DEFAULT_BOOKING,
  action: TBookingAction | TBookingCreateRemoveErrorsAction,
): TReducerBooking => {
  const { getFormattedPrice } = !!prevState.room
    ? utilityGetPrice({
        room: prevState.room,
        availability: prevState.availability,
        commercialConditions: prevState.commercialConditions,
      })
    : { getFormattedPrice: null };
  switch (action.type) {
    case 'BOOKING_SET_ROOM':
      return {
        ...prevState,
        room: action.payload,
        availability: undefined,
        commercialConditions: undefined,
        contractualConditions: undefined,
        applicationForm: {
          ...prevState.applicationForm,
          checkInDate: '',
          checkOutDate: '',
        },
      };
    case 'BOOKING_AVAILABILITY_REQUEST':
      return { ...prevState };
    case 'BOOKING_CONTRACTUAL_REQUEST':
    case 'BOOKING_COMMERCIAL_REQUEST':
      return {
        ...prevState,
      };
    case 'BOOKING_LIGHT_IDENTITY_REQUEST':
      return { ...prevState };
    case 'BOOKING_LIGHT_IDENTITY_SUCCESS':
      return {
        ...prevState,
        step: 'personalDetails',
        lightIdentity: action.payload,
        errors: {
          ...prevState.errors,
          contactDetails: undefined,
        },
      };
    case 'BOOKING_LIGHT_IDENTITY_FORM_FAILURE':
      if (action.payload)
        GTMBookingError(
          prevState.room?.code || '',
          getFormattedPrice?.() || 0,
          'step_1_light_identity',
          prevState.formId,
        );

      return {
        ...prevState,
        applicationForm: {
          ...prevState.applicationForm,
          contactDetailsErrors: action.payload,
        },
      };
    case 'BOOKING_LIGHT_IDENTITY_FAILURE':
      GTMBookingError(
        prevState.room?.code || '',
        getFormattedPrice?.() || 0,
        'step_1_light_identity',
        prevState.formId,
      );
      const newErrorState: Partial<TReducerBooking> = {
        errors: {
          ...prevState.errors,
          contactDetails: action.payload,
        },
      };
      if (!action.payload?.length) {
        newErrorState.error = BookingErrorStates.GENERIC_API;
      }
      return {
        ...prevState,
        ...newErrorState,
      };
    case 'BOOKING_VALIDATE_ROOM_REQUEST':
      return {
        ...prevState,
      };
    case 'BOOKING_VALIDATE_ROOM_SUCCESS':
      return {
        ...prevState,
      };
    case 'BOOKING_AVAILABILITY_SUCCESS':
      return {
        ...prevState,
        availability: action.payload,
      };
    case 'BOOKING_OPTIONS_SET':
      return {
        ...prevState,
        bookingOptions: action.payload,
      };
    case 'BOOKING_CONTRACTUAL_SUCCESS':
      return {
        ...prevState,
        contractualConditions: action.payload,
      };
    case 'BOOKING_COMMERCIAL_SUCCESS':
      return {
        ...prevState,
        commercialConditions: action.payload,
      };
    case 'BOOKING_VALIDATE_ROOM_FAILURE':
    case 'BOOKING_AVAILABILITY_FAILURE':
      GTMBookingError(
        prevState.room?.code || '',
        getFormattedPrice?.() || 0,
        'step_4_room_not_available',
        prevState.formId,
      );
      return {
        ...prevState,
        error: BookingErrorStates.NO_AVAILABILITY,
      };
    case 'BOOKING_CONTRACTUAL_FAILURE':
    case 'BOOKING_COMMERCIAL_FAILURE':
    case 'BOOKING_GENERIC_FAILURE':
      GTMBookingError(prevState.room?.code || '', getFormattedPrice?.() || 0, 'step_4_other', prevState.formId);
      return {
        ...prevState,
        error: BookingErrorStates.GENERIC_API,
      };
    case 'BOOKING_SET_CHECK_IN_DATE':
      return {
        ...prevState,
        applicationForm: {
          ...prevState.applicationForm,
          checkInDate: action.payload,
        },
      };
    case 'BOOKING_RESET_VALID_CHECKOUT_DATES':
      return {
        ...prevState,
        applicationForm: {
          ...prevState.applicationForm,
          validCheckOutDates: undefined,
        },
      };
    case 'BOOKING_SET_CHECK_OUT_DATE':
      return {
        ...prevState,
        applicationForm: {
          ...prevState.applicationForm,
          checkOutDate: action.payload,
        },
      };
    case 'BOOKING_SET_CONTACT_DETAILS':
      return {
        ...prevState,
        applicationForm: {
          ...prevState.applicationForm,
          contactDetails: action.payload,
        },
      };
    case 'BOOKING_SET_PERSONAL_DETAILS':
      return {
        ...prevState,
        applicationForm: {
          ...prevState.applicationForm,
          personalDetails: action.payload,
        },
      };
    case 'BOOKING_SET_PERSONAL_DETAILS_FORM_ERROR':
      return {
        ...prevState,
        applicationForm: {
          ...prevState.applicationForm,
          personalDetailsErrors: action.payload,
        },
      };
    case 'BOOKING_SET_PERSONAL_DETAILS_ERROR':
      return {
        ...prevState,
        errors: {
          ...prevState.errors,
          personalDetails: action.payload,
        },
      };
    case 'BOOKING_SET_SECOND_OCCUPANT':
      return {
        ...prevState,
        applicationForm: {
          ...prevState.applicationForm,
          secondOccupant: action.payload,
        },
      };
    case 'BOOKING_SET_SECOND_OCCUPANT_FORM_ERROR':
      return {
        ...prevState,
        applicationForm: {
          ...prevState.applicationForm,
          secondOccupantErrors: action.payload,
        },
      };
    case 'BOOKING_SET_SECOND_OCCUPANT_ERROR':
      return {
        ...prevState,
        errors: {
          ...prevState.errors,
          secondOccupant: action.payload,
        },
      };
    case 'BOOKING_SET_RESIDENTIAL_ADDRESS':
      return {
        ...prevState,
        applicationForm: {
          ...prevState.applicationForm,
          residentialAddress: action.payload,
        },
      };
    case 'BOOKING_SET_RESIDENTIAL_ADDRESS_FORM_ERROR':
      return {
        ...prevState,
        applicationForm: {
          ...prevState.applicationForm,
          residentialAddressErrors: action.payload,
        },
      };
    case 'BOOKING_SET_RESIDENTIAL_ADDRESS_ERROR':
      return {
        ...prevState,
        errors: {
          ...prevState.errors,
          residentialAddress: action.payload,
        },
      };
    case 'BOOKING_SET_FINANCIAL_VALIDATION':
      return {
        ...prevState,
        applicationForm: {
          ...prevState.applicationForm,
          financialValidation: action.payload,
        },
      };
    case 'BOOKING_SET_FINANCIAL_VALIDATION_FORM_ERROR':
      return {
        ...prevState,
        applicationForm: {
          ...prevState.applicationForm,
          financialValidationErrors: action.payload,
        },
      };
    case 'BOOKING_SET_FINANCIAL_VALIDATION_ERROR':
      return {
        ...prevState,
        errors: {
          ...prevState.errors,
          financialValidation: action.payload,
        },
      };
    case 'BOOKING_SET_VALID_CHECKOUT':
      return {
        ...prevState,
        applicationForm: {
          ...prevState.applicationForm,
          validCheckOutDates: action.payload,
        },
      };
    case 'BOOKING_SET_STEP_REQUEST': {
      return {
        ...prevState,
      };
    }
    case 'BOOKING_SET_STEP': {
      return {
        ...prevState,
        step: action.payload,
      };
    }
    case 'BOOKING_SET_HOW_FIND_US': {
      return {
        ...prevState,
        applicationForm: {
          ...prevState.applicationForm,
          howDidYouFindUs: action.payload,
        },
      };
    }
    case 'BOOKING_SET_HOW_FIND_US_FORM_ERROR':
      return {
        ...prevState,
        applicationForm: {
          ...prevState.applicationForm,
          howDidYouFindUsErrors: action.payload,
        },
      };

    case 'BOOKING_ACTIVATE_PAYMENT_STEP': {
      return {
        ...prevState,
        step: 'payment',
      };
    }
    case 'BOOKING_CREATE_REQUEST':
      return {
        ...prevState,
      };
    case 'BOOKING_CREATE_SUCCESS': {
      return {
        ...prevState,
        step: 'payment',
        paymentInfo: action.payload,
      };
    }
    case 'BOOKING_CREATE_FAILURE': {
      return {
        ...prevState,
        errors: {
          ...prevState.errors,
          ...action.payload,
        },
      };
    }

    case 'BOOKING_CREATE_REMOVE_ERRORS': {
      // errors need to be updated in the store
      return {
        ...prevState,
        errors: {
          ...prevState.errors,
          [action.payload.type]: [], // TODO: improve type safety
        },
      };
    }
    case 'BOOKING_CREATE_REMOVE_ALL_ERRORS': {
      // errors need to be updated in the store
      return {
        ...prevState,
        errors: {
          ...prevState.errors,
          personalDetails: [],
          contactDetails: [],
          secondOccupant: [],
          residentialAddress: [],
          generic: [],
        },
      };
    }
    case 'BOOKING_RESET_STORE': {
      return {
        ...DEFAULT_BOOKING,
        applicationForm: prevState.applicationForm,
        step: prevState.step !== 'contacts' ? 'personalDetails' : 'contacts',
      };
    }
    case 'BOOKING_ACTIVATE_COUPON_CODE': {
      return {
        ...prevState,
        couponCode: action.payload,
      };
    }
    case 'BOOKING_REMOVE_COUPON_CODE': {
      return {
        ...prevState,
        couponCode: {},
      };
    }
    case 'BOOKING_ADD_LOADER': {
      const newLoaders = [...prevState.loaders];
      if (!newLoaders.includes(action.payload)) {
        newLoaders.push(action.payload);
      }

      return {
        ...prevState,
        loaders: newLoaders,
      };
    }
    case 'BOOKING_REMOVE_LOADER': {
      return {
        ...prevState,
        loaders: prevState.loaders.filter(loader => loader !== action.payload),
      };
    }
    case 'BOOKING_SET_FORM_ID': {
      return {
        ...prevState,
        formId: action.payload,
      };
    }
    default:
      return prevState;
  }
};
