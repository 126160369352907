/** @format */

import { TReducerBookingOld } from '@/ducks/booking/selectors';
import { TRoom } from '../../@ducks/room/declarations';

interface Props {
  room: TRoom;
  commercialConditions?: TReducerBookingOld['commercialConditions'];
  availability?: TReducerBookingOld['availability'];
}

export const utilityGetPrice = ({ commercialConditions, availability, room }: Props) => {
  const hasPromo = () => {
    if (commercialConditions?.discountedPrice) {
      return true;
    }

    if (availability?.discountedPrice) {
      return true;
    }

    return room?.basePrice !== room?.discountedPrice && !!room?.discountedPrice;
  };

  const getDiscountedPrice = () => {
    if (!hasPromo()) return 0;
    if (commercialConditions?.discountedPrice) return commercialConditions.discountedPrice;
    else if (availability?.discountedPrice) return availability.discountedPrice;
    else return room?.discountedPrice;
  };

  const getDiscountedPricePercentage = () => {
    if (!hasPromo()) {
      return 0;
    }

    const discountedPrice =
      commercialConditions?.discountedPrice ?? availability?.discountedPrice ?? room.discountedPrice;
    const basePrice = commercialConditions?.price ?? availability?.price ?? room.basePrice;

    return ((basePrice - discountedPrice) / basePrice) * 100;
  };

  const getBasePrice = () => {
    if (commercialConditions?.price) return commercialConditions.price;
    else if (availability?.price) return availability.price;
    else return room?.basePrice;
  };

  const commercialConditionPrice = commercialConditions?.discountedPrice
    ? commercialConditions?.discountedPrice
    : commercialConditions?.price;

  const availabilityPrice = availability?.discountedPrice ? availability?.discountedPrice : availability?.price;

  const roomPrice = room?.discountedPrice ? room.discountedPrice : room?.basePrice;

  const getFormattedPrice = () => {
    if (!!commercialConditionPrice) return commercialConditionPrice;
    else if (!!availabilityPrice) return availabilityPrice;
    else return roomPrice;
  };

  const getDiscountUntilDate = () => {
    if (!hasPromo()) {
      return '';
    }
    return commercialConditions?.discountUntil || availability?.discountUntil || room?.discountUntil || null;
    // il  NULL è caso di errore in cui la chiave non viene passata da api';
  };

  const getDimension: 'special_offer' | 'full_price' = hasPromo() ? 'special_offer' : 'full_price';

  const getMetric = hasPromo() ? getBasePrice() - getDiscountedPrice() : 0;

  return {
    getDiscountedPrice,
    getDiscountedPricePercentage,
    getBasePrice,
    hasPromo,
    getFormattedPrice,
    getDiscountUntilDate,
    getMetric,
    getDimension,
  };
};
