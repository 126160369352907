/**
 * Since backend has changed the sorting behaviour, we need to adapt the sorting object to the new format.
 *
 * New Version:
 * {
 *   sorting: {
 *     orderBy: 'availability',
 *     ascOrder: true
 *   }
 * }
 *
 * Old version:
 * {
 *   orderBy: 'availability_asc'
 * }
 *
 * @format
 * @example
 * @example
 */

export const getListingSorting = (sorting: string = 'availability_asc') => {
  if (sorting === 'null') {
    return {
      sorting: {
        orderBy: 'availability',
        ascOrder: true,
      },
    };
  }
  return {
    sorting: {
      orderBy: sorting.split('_')[0],
      ascOrder: sorting.split('_')[1] === 'asc',
    },
  };
};
