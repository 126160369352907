/** @format */

import moment from 'moment';
import { TListType } from '../../general/declarations';
import { TRoom } from '../../room/declarations';
import { TLocale } from '@/utils/gatsby/types';
import { nanoid } from 'nanoid';

const event = 'GAevent';

declare global {
  interface Window {
    dataLayer: any;
  }
}

export const utilityGTMBlogSetup = (country: string, language: string, pageType: string) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'wSetup',
    country,
    language,
    currencyCode: 'EUR',
    pageType,
    marketingCookie: 1,
  });
};

export const utilityGtmBlogCategory = (category: string) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'GAevent',
    eventID: '76',
    eventCategory: 'blog',
    eventAction: 'blog_catgory',
    eventLabel: category,
  });
};

export const utilityGtmBlogSearch = (input: string) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'GAevent',
    eventID: '75',
    eventCategory: 'blog',
    eventAction: 'search_article',
    eventLabel: input,
  });
};

export const utilityGtmBlogSocial = (social: string, moreInfo: string) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'GAevent',
    eventID: '74',
    eventCategory: 'blog',
    eventAction: 'share_article',
    eventLabel: social,
    moreInfo,
  });
};

export const utilityGtmBlog = (category: string, name: string) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'GAevent',
    eventID: '73',
    eventCategory: 'blog',
    eventAction: 'article',
    eventLabel: category,
    moreInfo: name,
  });
};

export const utilityGtmWywgCarousel = (interaction: string, section: string) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'GAevent',
    eventID: '70',
    eventCategory: 'what_you_will_get',
    eventAction: 'carousel',
    eventLabel: interaction,
    moreInfo: section,
  });
};

export const utilityGtmWywgService = (info: string) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'GAevent',
    eventID: '71',
    eventCategory: 'what_you_will_get',
    eventAction: 'our_services',
    eventLabel: info,
  });
};

export const utilityGtmWywgPatners = (info: string) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'GAevent',
    eventID: '72',
    eventCategory: 'what_you_will_get',
    eventAction: 'our_partners',
    eventLabel: info,
  });
};

export const utilityGtmHelpCenterDescription = (eventLabel: string, moreInfo: string) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'GAevent',
    eventID: '21',
    eventCategory: 'help_center',
    eventAction: 'faq',
    eventLabel,
    moreInfo,
  });
};

export const utilityGtmHelpCenter = (eventAction: string, eventLabel: string, moreInfo: string) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'GAevent',
    eventID: '20',
    eventCategory: 'help_center',
    eventAction,
    eventLabel,
    moreInfo,
  });
};

export const utilityGtmMoreOption = (choice: 'filter' | 'listing_room', selection: string, moreInfo: string) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'GAevent',
    eventID: '52',
    eventCategory: 'room_detail',
    eventAction: `more_options_${choice}`,
    eventLabel: selection.toLowerCase().replace(/\s/g, '_'),
    moreInfo,
  });
};

export const utilityGtmGalleryImage = (eventLabel: string, moreInfo: number, monthlyRate: number) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'GAevent',
    eventID: '64',
    eventCategory: 'room_detail',
    eventAction: 'image_gallery',
    eventLabel,
    moreInfo,
    monthlyRate,
  });
};

export const utilityGtmSendFilter = ({ filterSelected, filterCategory }: any) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'GAevent',
    eventID: '29',
    eventCategory: 'filter',
    eventAction: filterCategory,
    eventLabel: filterSelected,
  });
};

export const utilityGtmThankYouPagePhone = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'GAevent',
    eventID: '40',
    eventCategory: 'room_detail',
    eventAction: 'typ',
    eventLabel: 'get_call',
  });
};

// TO BE DELETED ONCE PDP IS ONLINE
export const GTMVisitOtherRooms = (eventLabel: string, moreInfo: string) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'GAevent',
    eventID: '48',
    eventCategory: 'room_detail',
    eventAction: 'visit_other_room',
    eventLabel,
    moreInfo,
  });
};

export const GTMVisitOtherRoomsv2 = (roomId: string, roomRef: string, monthlyRate: number) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'GAevent',
    eventID: '95',
    eventCategory: 'room_detail',
    eventAction: 'visit_other_room',
    eventLabel: roomId,
    moreInfo: roomRef,
    monthlyRate: monthlyRate,
    event_name: 'pdp_visit_other_room',
    label: roomId,
  });
};

export const GTMHelpSupport = (moreInfo: string, monthlyRate: number) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'GAevent',
    eventID: '63',
    eventCategory: 'room_detail',
    eventAction: 'help_support',
    eventLabel: 'help_support',
    moreInfo,
    monthlyRate,
  });
};

export const GTMWywg = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'GAevent',
    eventID: '68',
    eventCategory: 'form',
    eventAction: 'find_my_stay',
    eventLabel: 'step1',
  });
};

export const GTMLandingCity = (city: string, country: string) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'GAevent',
    eventID: '69',
    eventCategory: 'landing_affiliation',
    eventAction: `listing_${country}`,
    eventLabel: city,
  });
};

export const GTMFilterHomepage = (eventAction: string, eventLabel: string, moreInfo: string) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'GAevent',
    eventID: '29',
    eventCategory: 'filter_homepage',
    eventAction,
    eventLabel,
    moreInfo,
  });
};

export const GTMsetup = (country: string, language: TLocale, pageType: string) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'wSetup',
    country,
    language,
    currencyCode: 'EUR',
    pageType,
    marketingCookie: 1,
  });
};

export const GTMheader = (eventAction: string, eventLabel?: string) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event,
    eventID: '02',
    eventCategory: 'header',
    eventAction,
    eventLabel: eventLabel || eventAction,
  });
};

export const GTMsocial = (social: string) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event,
    eventID: '03',
    eventCategory: 'social',
    eventAction: 'click',
    eventLabel: social,
  });
};

export const GTMfooter = (item: string) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event,
    eventID: '04',
    eventCategory: 'footer',
    eventAction: 'click',
    eventLabel: item,
  });
};

export const GTMhomePage = (section: string, cta: string) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event,
    eventID: '05',
    eventCategory: 'homepage',
    eventAction: section,
    eventLabel: cta,
  });
};

export const GTMlandingAwinCities = (city: string) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event,
    eventID: '69',
    eventCategory: 'landing_affiliation',
    eventAction: 'listing_italy',
    eventLabel: city,
  });
};

export const GTMmenu = (eventAction: string, eventLabel: string) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event,
    eventID: '01',
    eventCategory: 'menu',
    eventAction,
    eventLabel,
  });
};

export const GTMinfoRequest = (moreInfo: string, monthlyRate: number, pageType: string) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event,
    eventID: '54',
    eventCategory: 'room_detail',
    eventAction: 'ask_information',
    eventLabel: 'step1',
    moreInfo,
    monthlyRate,
    pageType,
  });
};

export const GTMinfoRequestContinue = (
  eventLabel: string,
  moreInfo: string,
  formID: string,
  timestamp: string,
  monthlyRate: number,
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event,
    eventID: '55',
    eventCategory: 'room_detail',
    eventAction: 'ask_information',
    eventLabel: `step2_${eventLabel}`,
    moreInfo,
    formID,
    timestamp,
    monthlyRate,
  });
};

export const GTMinfoRequestBack = (moreInfo: string, monthlyRate: number) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event,
    eventID: '57',
    eventCategory: 'room_detail',
    eventAction: 'ask_information',
    eventLabel: 'back',
    moreInfo,
    monthlyRate,
  });
};

export const GTMinfoRequestEnd = (
  eventLabel: string,
  moreInfo: string,
  formID: string,
  timestamp: string,
  monthlyRate: number,
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event,
    eventID: '56',
    eventCategory: 'room_detail',
    eventAction: 'ask_information',
    eventLabel: `ask_information_ok_${eventLabel}`,
    moreInfo,
    formID,
    timestamp,
    monthlyRate,
  });
};

export const GTMbookVisit = (moreInfo: string, monthlyRate: number, pageType: string) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event,
    eventID: '58',
    eventCategory: 'room_detail',
    eventAction: 'book_a_visit',
    eventLabel: 'step1',
    moreInfo,
    monthlyRate,
    pageType,
  });
};

export const GTMbookVisitContinue = (
  eventLabel: string,
  moreInfo: string,
  formID: string,
  timestamp: string,
  monthlyRate: number,
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event,
    eventID: '59',
    eventCategory: 'room_detail',
    eventAction: 'book_a_visit',
    eventLabel: `step2_${eventLabel}`,
    moreInfo,
    formID,
    timestamp,
    monthlyRate,
  });
};

export const GTMbookVisitBack = (moreInfo: string, monthlyRate: number) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event,
    eventID: '61',
    eventCategory: 'room_detail',
    eventAction: 'book_a_visit',
    eventLabel: 'back',
    moreInfo,
    monthlyRate,
  });
};

export const GTMbookVisitEnd = (
  eventLabel: string,
  moreInfo: string,
  formID: string,
  timestamp: string,
  monthlyRate: number,
  status: string,
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event,
    eventID: '60',
    eventCategory: 'room_detail',
    eventAction: 'book_a_visit',
    eventLabel: `book_a_visit_ok_${eventLabel}`,
    moreInfo,
    formID,
    timestamp,
    monthlyRate,
    status,
  });
};

export const GTMbookRoom = (moreInfo: string, formID: string, timestamp: string, monthlyRate: number) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event,
    eventID: '65',
    eventCategory: 'room_detail',
    eventAction: 'book_a_room',
    eventLabel: 'book_a_room_ok',
    moreInfo,
    formID,
    timestamp,
    monthlyRate,
  });
};

export const GTMBookARoom = (roomCode: string, monthlyRate: number) => {
  const timestamp = moment().format('YYYYMMDD_HHmmss');
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event,
    event_name: 'book_a_room',
    label: monthlyRate,
    position: 'room_detail',
    product: roomCode,
    brand: 'dovevivo',
    step: 'step1',
    timestamp,
    formID: nanoid(),
  });
};

export const GTMAddToCart = (
  roomCode: string,
  monthlyRate: number,
  roomName: string,
  cityNeighborood: string,
  roomType: string,
  discountOrFull: string,
  availability: string,
  position: string,
  order: string,
  filter: string,
) => {
  const timestamp = moment().format('YYYYMMDD_HHmmss');
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'add_to_cart',
    brand: 'dovevivo',
    ecommerce: {
      currency: 'EUR',
      order,
      filter,
      items: [
        {
          item_name: roomName,
          item_id: roomCode,
          price: monthlyRate,
          item_brand: cityNeighborood,
          item_category: roomType,
          item_list_name: 'search_result' as TListType,
          rate_type: discountOrFull,
          position,
          availability,
        },
      ],
    },
  });
};

export const GTMBookingContacts = (roomCode: string, monthlyRate: number, formId: string) => {
  const timestamp = moment().format('YYYYMMDD_HHmmss');
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event,
    event_name: 'book_a_room',
    label: monthlyRate,
    position: 'checkout',
    product: roomCode,
    brand: 'dovevivo',
    step: 'step2',
    formID: formId,
    timestamp,
  });
};

export const GTMBookingToPayment = (roomCode: string, monthlyRate: number, howDidYouFindUs: string, formID: string) => {
  const timestamp = moment().format('YYYYMMDD_HHmmss');
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event,
    event_name: 'book_a_room',
    label: monthlyRate,
    position: 'checkout',
    product: roomCode,
    brand: 'dovevivo',
    step: 'step3',
    type: howDidYouFindUs,
    timestamp,
    formID,
  });
};

export const GTMBookingConfirm = (roomCode: string, monthlyRate: number, formID: string) => {
  const timestamp = moment().format('YYYYMMDD_HHmmss');
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event,
    event_name: 'book_a_room',
    label: monthlyRate,
    position: 'checkout',
    product: roomCode,
    brand: 'dovevivo',
    step: 'step4',
    timestamp,
    formID,
  });
};

export const GTMBookingEditDates = (roomCode: string, monthlyRate: number, dates: string[], formID: string) => {
  const startDate = new Date(dates[0]);
  const endDate = new Date(dates[1]);
  const monthsDiff = Math.round((endDate.getTime() - startDate.getTime()) / 1000 / 60 / 60 / 24 / 30);
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event,
    event_name: 'edit_dates',
    label: monthlyRate,
    position: 'checkout',
    product: roomCode,
    brand: 'dovevivo',
    period: `${monthsDiff}_months`,
    date: `${moment(startDate).format('YYYYMMDD_HHmmss')} - ${moment(endDate).format('YYYYMMDD_HHmmss')}`,
    formID,
  });
};

export const GTMBookingFinancialOption = (roomCode: string, type: string, formID: string) => {
  const timestamp = moment().format('YYYYMMDD_HHmmss');
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event,
    event_name: 'financial_validation',
    position: 'checkout',
    product: roomCode,
    brand: 'dovevivo',
    formID,
    type,
    timestamp,
  });
};

export const GTMBookingTaxCodeModal = (roomCode: string, type: string, formID: string) => {
  const timestamp = moment().format('YYYYMMDD_HHmmss');
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event,
    event_name: 'tax_code',
    position: 'checkout',
    product: roomCode,
    brand: 'dovevivo',
    formID,
    type,
    timestamp,
  });
};

export const GTMBookingDownloadApp = (roomCode: string, type: string, formID: string) => {
  const timestamp = moment().format('YYYYMMDD_HHmmss');
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event,
    event_name: 'download_app',
    position: 'typ',
    product: roomCode,
    brand: 'dovevivo',
    type,
    timestamp,
    formID,
  });
};

export const GTMBookingError = (roomCode: string, monthlyRate: number | string, step: string, formID: string) => {
  const timestamp = moment().format('YYYYMMDD_HHmmss');
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event,
    event_name: 'book_room_error',
    position: 'checkout',
    label: monthlyRate,
    product: roomCode,
    brand: 'dovevivo',
    step,
    timestamp,
    formID,
  });
};

export const GTMmapInteraction = (price: number) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event,
    eventID: '27',
    eventCategory: 'map_interaction',
    eventAction: 'pin_price',
    eventLabel: `${price}€`,
  });
};

export const GTMWhatsAppCta = (roomCode: TRoom['code'], price: number) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'GAevent',
    eventID: '88',
    eventCategory: 'room_detail',
    eventAction: 'whatsapp_cta',
    eventLabel: roomCode,
    event_name: 'whatsapp_cta',
    product: roomCode,
    label: price,
  });
};

export const GTMmapInteractionCard = (price: number, roomRef: string) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'GAevent',
    eventID: '28',
    eventCategory: 'map_interaction',
    eventAction: 'listing',
    eventLabel: `${price}€`,
    moreInfo: roomRef,
  });
};

export const GTMproductImpression = (
  impressions: Array<{
    name: string;
    id: string;
    price: number;
    brand: string;
    category: string;
    list: TListType;
    position: number;
    dimension17: string;
    availability: string;
  }>,
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'productImpression',
    ecommerce: {
      currencyCode: 'EUR',
      impressions,
    },
  });
};

export const GTMproductClick = (click: {
  actionField: { list: TListType };
  products: [
    {
      name: string;
      id: string;
      price: number;
      brand: string;
      category: string;
      variant: string;
      position: number;
      dimension17: string;
      metric1: number;
      availability: string;
    },
  ];
}) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'productClick',
    ecommerce: {
      currencyCode: 'EUR',
      click,
    },
  });
};

export const GTMproductDetail = ({ order, filter, item }: { order: string; filter: string; item: TGTMBookingItem }) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'view_item',
    brand: 'dovevivo',
    ecommerce: {
      currency: 'EUR',
      order,
      filter,
      items: [item],
    },
  });
};

export const GTMaddToCart = (add: {
  products: [
    {
      name: string;
      id: string;
      price: number;
      brand: string;
      category: string;
      variant: string;
      quantity: string;
      dimension17: string;
      metric1: number;
    },
  ];
}) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'addToCart',
    ecommerce: {
      currencyCode: 'EUR',
      add,
    },
  });
};

export const GTMblockRoom = (position: string, moreInfo: string) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event,
    eventID: '53',
    eventCategory: 'room_detail',
    eventAction: 'book_a_room',
    eventLabel: position,
    moreInfo,
  });
};

export const GTMcheckOut = (checkout: {
  actionField: { step: number };
  products: [
    {
      name: string;
      id: string;
      price: number;
      brand: string;
      category: string;
      variant: string;
      quantity: string;
      dimension17: string;
      metric1: number;
    },
  ];
}) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'checkout',
    ecommerce: {
      currencyCode: 'EUR',
      checkout,
    },
  });
};

export const GTMthankYouPage = (purchase: {
  actionField: {
    id: string;
    revenue: number;
    tax: string;
    metric5: number;
    metric6: number;
    metric7: number;
    dimension20: number | string;
    dimension21: string;
  };
  products: [
    {
      name: string;
      id: string;
      price: number;
      brand: string;
      category: string;
      variant: string;
      quantity: string;
      dimension17: 'special_offer' | 'full_price';
      metric1: number;
    },
  ];
}) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'purchase',
    ecommerce: {
      currencyCode: 'EUR',
      purchase,
    },
  });
};

export const GTMvirtualTour = ({
  eventLabel,
  moreInfo,
  monthlyRate,
}: {
  eventLabel: string;
  moreInfo: string;
  monthlyRate: number;
}) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'GAevent',
    eventID: '77',
    eventCategory: 'virtual_tour',
    eventAction: 'click',
    eventLabel,
    moreInfo,
    monthlyRate,
  });
};

export const GTMCityAndNeighborhoodSectionFilter = (
  city: string,
  filterSelected: string,
  currentPage: 'city_page' | 'neighborhood_page',
  currentPageFiltered: 'citypage_filter' | 'neighborhoodpage_filter',
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'GAevent',
    eventID: '81',
    eventCategory: currentPage,
    eventAction: 'filter',
    eventLabel: filterSelected.toLowerCase().replaceAll(' ', '_'),
    moreInfo: city.toLowerCase().replaceAll(' ', '_'),
    event_name: currentPageFiltered,
    label: filterSelected.toLowerCase().replaceAll(' ', '_'),
    type: city.toLowerCase().replaceAll(' ', '_'),
  });
};

export const GTMCityAndNeighborhoodArticle = (
  city: string,
  articleSelected: string,
  currentPage: 'city_page' | 'neighborhood_page',
  filterArticle: 'citypage_article' | 'neighborhood_article',
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'GAevent',
    eventID: '82',
    eventCategory: currentPage,
    eventAction: 'read_article',
    eventLabel: articleSelected,
    moreInfo: city.toLowerCase().replaceAll(' ', '_'),
    event_name: filterArticle,
    label: articleSelected,
    type: city.toLowerCase().replaceAll(' ', '_'),
  });
};

export const GTMCityAndNeighborhoodsApartmentCarousel = (
  city: string,
  roomRef: string,
  currentPage: 'city_page' | 'neighborhood_page',
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'GAevent',
    eventID: '83',
    eventCategory: currentPage,
    eventAction: 'apartment_carousel',
    eventLabel: roomRef,
    moreInfo: city.toLowerCase().replaceAll(' ', '_'),
    event_name: 'apartment_carousel',
    position: currentPage,
    product: roomRef,
    type: city.toLowerCase().replaceAll(' ', '_'),
  });
};

export const GTMCityAndNeighborhoodApartmentCta = (
  city: string,
  currentPage: 'city-page' | 'neighborhood-page',
  currentPageCTA: 'citypage_cta' | 'neighborhoods_cta',
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'GAevent',
    eventID: '84',
    eventCategory: currentPage.replace('-', '_'),
    eventAction: 'apartment_cta',
    eventLabel: city.toLowerCase().replaceAll(' ', '_'),
    event_name: currentPageCTA,
    label: 'apartment_carousel',
    type: city.toLowerCase().replaceAll(' ', '_'),
  });
};

export const GTMCityAndNeighborhoodFaq = (
  city: string,
  selection: string,
  currentPage: 'city-page' | 'neighborhood-page',
  currentPageFaq: 'citypage_faq' | 'neighborhoodpage_faq',
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'GAevent',
    eventID: '85',
    eventCategory: currentPage.replace('-', '_'),
    eventAction: 'faq_interaction',
    eventLabel: selection,
    moreInfo: city.toLowerCase().replaceAll(' ', '_'),
    event_name: currentPageFaq,
    label: selection,
    type: city.toLowerCase().replaceAll(' ', '_'),
  });
};

export const GTMCityOtherCityCarousel = (city: string, selectionClick: string) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'GAevent',
    eventID: '86',
    eventCategory: 'neighborhoods_page',
    eventAction: 'other_city_carousel',
    eventLabel: `${selectionClick}_click`,
    moreInfo: city.toLowerCase().replaceAll(' ', '_'),
    event_name: 'other_city_carousel',
    position: 'neighborhoods_page',
    label: `${selectionClick}_click`,
    type: city.toLowerCase().replaceAll(' ', '_'),
  });
};

export const GTMOtherNeighborhoodsCarousel = (
  city: string,
  selectionClick: string,
  currentPage: 'city_page' | 'neighborhood_page',
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'GAevent',
    eventID: '87',
    product: selectionClick.toLowerCase().replaceAll(' ', '_'),
    eventCategory: currentPage,
    eventAction: 'neighborhood_carousel',
    eventLabel: selectionClick.toLowerCase().replaceAll(' ', '_'),
    moreInfo: city.toLowerCase().replaceAll(' ', '_'),
    event_name: 'neighborhood_carousel',
    position: currentPage,
    type: city.toLowerCase().replaceAll(' ', '_'),
  });
};

export const GTMPDPMapInteractionZoom = (zoomAction: 'zoom_in' | 'zoom_out', roomRef: string, monthlyRate: number) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'GAevent',
    eventID: '90',
    eventCategory: 'pdp_map_interaction',
    eventAction: zoomAction,
    eventLabel: '',
    moreInfo: roomRef,
    monthlyRate: monthlyRate,
    event_name: 'pdp_map_zoom',
    type: zoomAction,
  });
};

export const GTMPDPMapInteractionAddressClick = (roomRef: string, monthlyRate: number) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'GAevent',
    eventID: '91',
    eventCategory: 'pdp_map_interaction',
    eventAction: 'open_maps',
    eventLabel: '',
    moreInfo: roomRef,
    monthlyRate: monthlyRate,
    event_name: 'pdp_open_maps',
  });
};

export const GTMPDPNeighborhoodClick = (roomRef: string, monthlyRate: number, neighborhood: string) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'GAevent',
    eventID: '51',
    eventCategory: 'room_detail',
    eventAction: 'discover_more_neighborhood',
    eventLabel: neighborhood,
    moreInfo: roomRef,
    monthlyRate: monthlyRate,
    event_name: 'pdp_open_neighborhood',
    label: neighborhood,
  });
};

export const GTMPDPRoomGalleryImageAndAllClick = (roomRef: string, monthlyRate: number) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'GAevent',
    eventID: '92',
    eventCategory: 'room_detail',
    eventAction: 'open_gallery',
    eventLabel: '',
    moreInfo: roomRef,
    monthlyRate: monthlyRate,
    event_name: 'pdp_open_gallery',
  });
};

export const GTMPDPRoomGalleryImageClick = (roomRef: string, monthlyRate: number, imagePosition: number) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'GAevent',
    eventID: '93',
    eventCategory: 'room_detail',
    eventAction: 'select_image',
    eventLabel: imagePosition,
    moreInfo: roomRef,
    monthlyRate: monthlyRate,
    event_name: 'pdp_select_image',
    label: imagePosition,
  });
};

export const GTMPDPRoomGalleryCarouselClick = (roomRef: string, monthlyRate: number, imagePosition: number) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'GAevent',
    eventID: '94',
    eventCategory: 'room_detail',
    eventAction: 'slide_gallery',
    eventLabel: imagePosition,
    moreInfo: roomRef,
    monthlyRate: monthlyRate,
    event_name: 'pdp_slide_gallery',
    label: imagePosition,
  });
};

export const GTMPDPShowAllServices = (roomRef: string, monthlyRate: number) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'GAevent',
    eventID: '96',
    eventCategory: 'room_detail',
    eventAction: 'services',
    eventLabel: 'show_all_services',
    moreInfo: roomRef,
    monthlyRate: monthlyRate,
    event_name: 'pdp_show_services',
  });
};

export const GTMUtilityBoxCta = (position: string, label: string) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event,
    event_category: 'homepage',
    event_name: 'hp_interaction',
    position: position,
    label: label,
    brand: 'dovevivo',
  });
};

type GTMListingFilterType = 'property_type' | 'check_in_date' | 'price_range' | 'points_of_interest' | 'all_filters';

type GTMListingFilterDetailType =
  | Exclude<GTMListingFilterType, 'all_filters'>
  | 'facilities'
  | 'amenities'
  | 'suitability';

export const GTMListingFilter = (label: GTMListingFilterType) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'GAevent',
    eventName: 'filter',
    label: label,
    brand: 'dovevivo',
  });
};

export const GTMListingFilterDetail = (type: GTMListingFilterDetailType, label: string) => {
  if (['', '|'].includes(label)) return;

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'GAevent',
    eventName: 'filter_detail',
    type: type,
    label: label,
    brand: 'dovevivo',
  });
};

export const GTMListingSortBy = (label: string) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'GAevent',
    eventName: 'sort_by',
    label: label,
    brand: 'dovevivo',
  });
};

export const GTMListingMapMarkerClick = (roomCode: string, price: number) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'GAevent',
    eventName: 'interacting_with_map',
    label: price,
    product: roomCode,
    type: 'pin_price',
    brand: 'dovevivo',
  });
};

export const GTMListingCard = (
  roomCode: string,
  interactionType: 'left_photo' | 'right_photo' | 'gallery' | 'videos' | 'planimetry' | 'map' | 'vr' | 'property_type',
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'GAevent',
    eventName: 'property_card',
    type: interactionType,
    product: roomCode,
    label: 'click',
    brand: 'dovevivo',
  });
};

export const GTMListingDiscoverCta = (city: string) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'GAevent',
    eventName: 'box_cta',
    type: city,
    label: 'discover_city',
    brand: 'dovevivo',
  });
};

export const GTMListingShowMoreSpeaces = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'GAevent',
    eventCaption: 'cta_show_more_spaces',
    tag: 'click',
    brand: 'dovevivo',
  });
};

export const GTMListingViewItemList = (
  items: Array<{
    item_name: string;
    item_id: string;
    price: number;
    item_brand: string;
    item_category: string;
    item_list_name: TListType;
    position: number;
    rate_type: 'full_price' | 'special_offer';
    availability: string;
  }>,
  order: string,
  filter: string,
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'view_item_list',
    brand: 'dovevivo',
    ecommerce: {
      currency: 'EUR',
      order: order,
      filter: filter,
      items,
    },
  });
};

export const GTMVirtualPageView = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'virtual_pageview',
  });
};

export const GTMListingSelectItem = (
  item: {
    item_name: string;
    item_id: string;
    price: number;
    item_brand: string;
    item_category: string;
    item_list_name: TListType;
    position: number;
    rate_type: 'full_price' | 'special_offer';
    availability: string;
  },
  order: string,
  filter: string,
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'item_selection',
    brand: 'dovevivo',
    ecommerce: {
      currency: 'EUR',
      order: order,
      filter: filter,
      items: [item],
    },
  });
};

export type TGTMBookingItem = {
  item_name?: string;
  item_id?: string;
  price?: number | null;
  item_brand?: string;
  item_category?: string;
  item_list_name?: TListType;
  position?: string;
  rate_type?: 'full_price' | 'special_offer';
  availability?: 'not_available' | 'available_on_next_weeks' | 'available';
  period?: string;
  fee?: number;
  deposit?: number;
  first_month_price?: number;
  promo_period?: string;
  discount?: number;
  formID?: string;
};

export const GTMBookingBeginCheckout = ({
  order,
  filter,
  item,
}: {
  order: string;
  filter: string;
  item: TGTMBookingItem;
}) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    ecommerce: null,
  });

  window.dataLayer.push({
    event: 'begin_checkout',
    brand: 'dovevivo',
    ecommerce: {
      date: moment().format('YYYYMMDD_HHmmss'),
      currency: 'EUR',
      order: order,
      filter: filter,
      items: [{ ...item, quantity: '1' }],
    },
  });
};

export const GTMBookingAddShippingInfo = ({
  order,
  filter,
  date,
  item,
}: {
  order: string;
  filter: string;
  date: string;
  item: TGTMBookingItem;
}) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    ecommerce: null,
  });

  window.dataLayer.push({
    event: 'add_shipping_info',
    brand: 'dovevivo',
    ecommerce: {
      currency: 'EUR',
      order: order,
      filter: filter,
      date,
      items: [{ ...item, quantity: '1' }],
    },
  });
};

export const GTMBookingAddPaymentInfo = ({
  order,
  filter,
  paymentType,
  date,
  item,
}: {
  order: string;
  filter: string;
  paymentType: 'stripe' | 'payline';
  date: string;
  item: TGTMBookingItem;
}) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    ecommerce: null,
  });

  window.dataLayer.push({
    event: 'add_payment_info',
    brand: 'dovevivo',
    ecommerce: {
      currency: 'EUR',
      order: order,
      filter: filter,
      payment_type: paymentType,
      date,
      items: [{ ...item, quantity: '1' }],
    },
  });
};

// Used only in stripe payment
export const GTMBookingPurchase = ({
  order,
  filter,
  id,
  revenue,
  tax,
  item,
}: {
  order: string;
  filter: string;
  id: string;
  revenue?: number | null;
  tax: number | null;
  item: TGTMBookingItem & { coupon?: string; second_occupant?: string; fee?: number | null };
  fee: number | null;
}) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    ecommerce: null,
  });

  window.dataLayer.push({
    event: 'purchase',
    brand: 'dovevivo',
    ecommerce: {
      currency: 'EUR',
      order: order,
      filter: filter,
      id,
      revenue,
      tax,
      items: [{ ...item, quantity: '1' }],
    },
  });
};

export const GTMAskInfoS1 = ({ monthlyRate, roomCode }: { monthlyRate: number; roomCode: string }) => {
  const timestamp = moment().format('YYYYMMDD_HHmmss');

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event,
    event_name: 'ask_information_s1',
    label: monthlyRate,
    position: 'room_detail',
    product: roomCode,
    brand: 'dovevivo',
    step: 'step1',
    period: timestamp,
  });
};

export const GTMAskInfoOk = ({ price, formId, roomCode }: { price: string; formId: string; roomCode: string }) => {
  const timestamp = moment().format('YYYYMMDD_HHmmss');

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event,
    event_name: 'ask_information_ok',
    label: price, // monthlyRate
    position: 'room_detail',
    product: roomCode,
    brand: 'dovevivo',
    step: 'step3',
    period: timestamp,
    formID: formId,
  });
};
